.payroll-check-date-wrapper {
  margin-top: -40px;
}
.repeat-funding-date-picker-wrapper {
  width: 400px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: space-between;
  background-color: white;
  width: 438px;
  height: 50px;
  padding: 13px 13px 13px 14px;
  gap: 0px;
  border-radius: 8px;
  border: 1px 1px 1px 1px;
  // opacity: 0px;
  border: 1px solid #dcdfe3;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.payroll-check-date-header {
  color: #323b4b;
  font-weight: bold;
  font-size: 12px;
  line-height: 14.4px;
  font-family: 'airbnb-cereal-medium';
}
.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.repeat-funding-calendar-icon {
  cursor: pointer;
}
.date-picker-input {
  position: relative;
  display: inline-block;
}

.date-picker-input .react-datepicker-wrapper {
  display: inline-block;
  width: 100%;
}

.date-picker-input .react-datepicker__input-container input {
  width: 100%;
  padding-right: 2rem;
  font-size: 14px;
  color: #323b4b;
  font-family: 'airbnb-cereal-light';
  font-weight: bold;
  cursor: pointer;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #888;
}
.payroll-check-date-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
