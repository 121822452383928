.total-payroll-amount-wrapper {
  width: 198px !important;
  // display: flex;
  flex-direction: row;
  align-items: center;
}
.payroll-amount-explanation {
  color: #323b4b;
  font-size: 11.5px;
}
