.bank-accounts-wrapper-settings {
}
.bank-accounts-title-settings {
  height: 44px;
  border-bottom: 1px solid #dcdfe3;
}

.bank-accounts-title-style {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.015em;
  color: #323b4b;
}
