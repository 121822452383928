@import '../../../common-styles.scss';
#deal-failed-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-top: 18px;
  padding-bottom: 18px;
  margin: auto;
  background-color: #ffffff;
  border-radius: 20px;
  width: 560px;
  max-width: 100%;
}
#failed-icon {
  margin-left: 75px;
  width: 500px;
  max-width: 100%;
  height: auto;
}
#failed-icon-repeat {
  width: auto;
  max-width: 100%;
  height: 283px;
}
#deal-failed-outer-wrapper-onboarding {
  background-color: #ffffff;
  //box-shadow: 0px 5px 25px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  // width: 498px;
  width: 560px;
  position: relative;
  max-width: 100%;
  margin: auto;
}
#payroll-failed-title {
  color: $typography-text-2;
  text-align: center;
  font-size: 36px;
  line-height: 1.25em;
  letter-spacing: 0.015em;
  font-family: 'causten-bold';
}
#payroll-failed-explanation {
  color: $typography-text-3;
  text-align: center;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.035em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'causten-regular';
}
#payroll-failed-date {
  background-color: $primary-purple-background;
  color: $primary-purple-700;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.025em;
  border-radius: 7px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'causten-medium';
  margin-top: 13px;
  margin-bottom: 42px;
}

#payroll-failed-date p {
  text-align: center;
}
.buttons-pending {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cancel-request {
  background: transparent;
  color: red;
  border: 1px solid #DCDFE3;
}