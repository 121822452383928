#section-marker-container {
  background-color: white;
  margin-left: 30px;
}

@media only screen and (max-width: 960px) {
  #section-marker-container {
    display: none;
  }
}

#need-help-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  width: 230px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 5px 25px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  margin-left: 75%;
}
#data-encrypted-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: absolute;
  top: 300px;
  margin-left: 75.5%;
  width: 280px;
}
#data-encrypted-container-home-new {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 300px;
  margin-left: 75.5%;
  width: 280px;
  margin-top: 12px;
}
.data-encrypted-text {
  font-family: 'airbnb-cereal-medium';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 155%;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
  color: #323b4b;
}
.need-help-text {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 155%;
  letter-spacing: 0.035em;
  color: #4e5d78;
  margin-top: -3%;
}
.need-help-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -6px;
}
.need-help-info-wrapper-phone {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 9px;
}

.phone-number {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: 0.015em;
  color: #4c2d6a;
  margin-top: -4px;
  margin-bottom: 2%;
}
.email-add {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.015em;
  color: #4c2d6a;
  margin-top: -4px;
  margin-bottom: 2%;
  margin-left: 2px;
}

.monday-fri {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 155%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.045em;
  color: #8a94a6;
  margin-top: 1px;
  margin-left: 17px;
}

.lock-icon {
  background: #f4f2fa;
  margin-top: 4.5%;
  margin-right: 2%;
}

@media only screen and (max-width: 960px) {
  #need-help-container {
    display: none;
  }
  #data-encrypted-container {
    display: none;
  }
  #data-encrypted-container-home-new {
    display: none;
  }
}

.section-marker {
  border-left: 4px solid #d2c9efcc;
  box-shadow: inset 0px -2px 0px rgba(207, 195, 255, 0.15);
  width: 260px;
  height: 90px;
  text-align: center;
  font-size: 16px;
  line-height: 150%;
  font-family: 'airbnb-cereal-medium';

  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  color: #4e5d78;
}

@media only screen and (max-width: 1050px) {
  .section-marker {
    width: 170px;
    font-size: 14px;
  }
  #need-help-container {
    margin-left: 80%;
    width: 190px;
  }
  #data-encrypted-container {
    margin-left: 80%;
    width: 190px;
  }
  #data-encrypted-container-home-new {
    margin-left: 80%;
    top: 315px;
    width: 190px;
  }
}

@media only screen and (min-width: 1050px) and (max-width: 1200px) {
  .section-marker {
    width: 200px;
    font-size: 14px;
  }
  #need-help-container {
    width: 220px;
    margin-left: 80%;
  }
  #data-encrypted-container {
    width: 220px;
    margin-left: 80%;
  }
  #data-encrypted-container-home-new {
    width: 220px;
    top: 315px;
    margin-left: 80%;
  }
}

.section-marker.selected {
  background-color: rgba(244, 242, 250, 0.5);
  border-left: 4px solid #653494;
}

.section-marker.checked {
  border-left: 4px solid #21b17d;
  text-align: left;
}
.section-marker.checked.selected {
  border-left: 4px solid #653494;
  text-align: left;
}

.section-marker-subtitle {
  font-size: 12px;
  line-height: 155%;
  /* identical to box height, or 19px */
  text-align: left;
  letter-spacing: 0.045em;

  /* Typhograpy/Text - 4 */

  color: #8a94a6;
}

.section-marker-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.section-marker-text p {
  margin-top: 1px;
  margin-bottom: 1px;
}
