#same-account-choice-spacer {
  margin-top: 30px;
}
#bank-form {
  border: 1px solid #dcdfe3;
  padding: 15px;
  border-radius: 8px;
}
#withdrawal-bank-form {
  border: 1px solid #dcdfe3;
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
}
#connectacc{
  color: var(--typhograpy-text-2, #323B4B);
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.3px;
}
#chasee{
  color: var(--typhograpy-text-2, #323B4B);
  font-family: Causten;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.27px;
  margin: 0%;
}
.testtext {
  display: flex;
}
.bank_data .connectaccouterbox{
  border: none;
  background: transparent;
  width: 100%;
}
.connectaccouterbox {
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--grayscale-black-300, #DCDFE3);
  background: #FFF;
  margin-bottom: 10px;
}
#Fundingg{
  color: var(--primary-purple-800, #4C2D6A);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 21.7px */
  letter-spacing: 0.49px;
  margin: 0%;
  display: flex;
  align-items: center;
}
#checkk{
  color: var(--typhograpy-text-3, #4E5D78);
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; 
  letter-spacing: 0.54px;
  margin: 0%;
}
#Disconn{
  color: var(--accent-danger-danger-700-main, #FF2B2B);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; 
  letter-spacing: 0.49px;
  margin: 0%;
  display: flex;
  align-items: center;
  float: right;
}
.imgcircle{
  padding-right: 15px;
}
.connectaccbox{
  margin-top: 12px;
}
#iconsmargn{
  // padding-top: 8px;
}
.section-bb.selected .connectaccouterbox {
  background: #6534942b;
}