.upload-container {
  border: 1px dashed #8755de;
  filter: drop-shadow(0px 10px 35px rgba(17, 12, 65, 0.03));
  border-radius: 8px;
  text-align: center;
  padding: 5px 15px;
  padding-top: 12px;
  height: 86px;
}

.upload-wrapper {
  margin-bottom: 20px;
}

input[type='file'] {
  display: none;
}

/* #icon-upload-wrapper {
} */

#upload-label {
  font-size: 12px;
  color: #4e5d78;
  font-style: normal;
  font-weight: 400;

  line-height: 155%;
  text-align: center;
  letter-spacing: 0.045em;
}

.refunding-popup .uploaded-file {
  padding: 3px 0;
}
.uploaded-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3px 30px 3px 30px;
}
.banksec .uploaded-file {
  padding: 0;
  margin-top: 20px;
}
.uploaded-file-name {
  font-weight: bold;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.file-icon {
  margin-right: 7px;
}

.icon-and-filename {
  display: flex;
  align-items: center;
  width: 80%;
}
img.eye-icon {
  cursor: pointer;
}
.trash-icon {
  cursor: pointer;
}
.contract h5{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-top: 0%;
}
.contract-text {
  color: var(--typhograpy-text-2, #323B4B);
  font-family: Causten;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  width: 525px;
  /* border: 1px solid grey; */
  padding: 10px;
}
.contract-text {
  height: 400px;
  max-height: 400px;
  overflow: scroll;
}
.contract-text::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

.contract-text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.outerbox{
  width: 291px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.10);
}
.outerbox p{
  display: flex;
  align-items: center;
  justify-content: center;
}
.non{
  width: 25%;
}
.bottemcontainer{
  display: flex;
  align-items: center;
  justify-content: center;
}
.scroll {
  margin: 18px 0px;
  display: flex;
  align-items: center;
  color: var(--accent-warning-warning-800, #FF9A3D);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
}
.click{
  margin-top: 20px;
  color: #8E8E8E;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}