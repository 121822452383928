@import '../../../common-styles.scss';

#funded-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  margin: auto;
  width: 590px;
  background-color: #ffff;
  box-shadow: 0px 35px 60px rgba(21, 21, 56, 0.03);
  border-radius: 20px;
  margin-bottom: 5% !important;
  padding-bottom: 4% !important;
  align-items: center;
}

#funded-icon {
  // margin-left: 75px;
  width: 350px;
  max-width: 100%;
  height: auto;
}
#funded-icon-repeat {
  width: auto;
  max-width: 100%;
  height: 283px;
}
@media only screen and (max-width: 600px) {
  #funded-icon {
    margin-left: 5px;
    width: 480px;
  }
  #funded-icon-repeat {
    margin-left: 0px !important;
  }
  #funded-wrapper {
    max-width: 95%;
  }
}

#payroll-funded-title {
  color: $typography-text-2;
  text-align: center;
  font-size: 36px;
  line-height: 1.25em;
  letter-spacing: 0.015em;
  font-family: 'causten-bold';
}
#payroll-funded-explanation {
  color: $typography-text-3;
  text-align: center;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.035em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'causten-regular';
}

#payroll-funded-explanation p {
  margin-top: 0px;
}

#payroll-funded-date {
  background-color: $primary-purple-background;
  color: $primary-purple-700;
  padding: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.025em;
  border-radius: 7px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'causten-medium';
  margin-top: 13px;
  margin-bottom: 20px;
}

#payroll-funded-date p {
  text-align: center;
}

#back-to-dashboard-container {
  display: flex;
  justify-content: center;
}

.info-blurb {
  margin-bottom: 20px;
}
.refunding-popup #funded-wrapper {
  width: auto;
}
.refunding-popup #funded-icon {
  margin: auto;
  width: 350px;
}
.mydashbord{
  background-color: #653494;
  color: white;
  margin: 7px;
  border-radius: 60px;
  border: 0px;
  text-transform: none;
  padding: 11px 28px;
  font-weight: bold;
  text-align: center;
  font-family: 'causten-bold';
  font-size: 14px;
  min-width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  letter-spacing: 0.015em;
  align-items: center;
  cursor: pointer;

}
.dashbord{
  color: white !important;
  display: flex;
  align-items: center;
  font-size: 12px;
  letter-spacing: 0.015em;
  text-decoration: none;
}
.right{
  margin: 0 0 0 10px;
}