#policies-page {
  min-height: 80vh;
  padding: 20px;
}


#d-container {
  width: 100%;
  padding: 0;
  border-radius: unset;
}

.payroll-funding-section {
  background: #653494;
  padding: 30px 40px;
}

.payroll-funding-section .section-title {
  font-size: 70px;
  font-weight: bold;
  line-height: 75px;
  color: #38BB9F;
  max-width: 60%;
  margin: 0 auto 45px;
  position: relative;
  text-align: center;
  -webkit-transition: 0.3s all ease-in-out;
  transition: 0.3s all ease-in-out;
}