#wire-info-page {
    padding: 20px;
    background-color: white;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    width: 500px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;

}
#wire-info-title {
    text-align: center;
    margin-bottom: 30px;
}