#skip-me {
  text-decoration: underline;
  text-decoration-color: #9d8af3;
  width: 400px;
  max-width: 100%;
  margin-top: 10px;
  margin-bottom: -17px;
  cursor: pointer;
}
#background-checks{
  color: var(--typhograpy-text-4, #8A94A6);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
}
.owner-information{
  color: var(--grayscale-black-800, #2F353B);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.checkbox-owner{
  width: 18px;
  height: 19px;
}
.owner-checkbox{
  flex-direction: row;
  color: #653494;
  font-size: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}