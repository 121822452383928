@import '../../../common-styles.scss';
.lendio-container-main {
  background-color: #ffffff;
  width: 1000px;
  height: 630px;
  border-radius: 12px;
  margin-bottom: 150px;
  margin-top: 20px;
  padding: 25px;
  border: 1px solid #e0ddf3;
}
.lendio-container-main-submitted {
  background-color: #ffffff;
  width: 700px;
  height: 400px;
  border-radius: 12px;
  margin-bottom: 150px;
  padding: 25px;
  border: 1px solid #e0ddf3;
}
.submitted {
  font-size: 22px !important;
}
.lendio-link-style {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.lendio-data-container {
  // width: 90%;
  padding: 25px;
  height: 350px;
  //background-color: hsl(255, 44%, 96%);
  border-radius: 12px;
  // margin-bottom: 150px;
  margin-top: 10px;
  border: 1px solid #e0ddf3;
}
.header-icon-wrapper-lendio {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 160px;
}
.lendio-header-and-payro-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #323b4b;
  font-weight: bolder;
  font-size: 22px;
}
.payro-logo-lendio-page {
  margin-top: 12px;
}
.subtitle-lendio {
  color: #323b4b;
  font-size: 12.5px;
  margin-top: -10px;
}
.wrapper-lendio-sub-title-explanation {
  //background-color: red;
  display: flex;
  margin-top: -20px;
  margin-bottom: 30px;
}
.lendio-header-and-payro-sub-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #323b4b;
  font-weight: 575;
  font-size: 15.5px;
}
.apply-now-button {
  background-color: #653494;
  color: white;
  margin: 7px;
  border-radius: 60px;
  border: 0px;
  text-transform: none;
  padding: 11px 28px;
  font-weight: bold;
  text-align: center;
  font-family: 'causten-bold';
  font-size: 16px;
  min-width: 90px;
  display: flex;

  flex-direction: row;
  justify-content: space-evenly;
  letter-spacing: 0.015em;
  align-items: center;
  cursor: pointer;
}
.disclosure-text {
  font-size: 10px;
  color: #323b4b;
  display: flex;
  padding: 3.5px;
}
.lendio-header-and-payro-logo-subwrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  //background-color: red;
}
.lendio-header-and-logo-subwrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
}
.lendio-header-text {
  color: #8a94a6;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tm {
  font-size: 0.5em; /* Smaller font size */
  vertical-align: super; /* Positions it like a superscript */
}
.lendio-logo {
}
.lineStyle {
  border-left: 1.5px solid #dcdfe3;
  // Defines the vertical line
  height: 25px; // Adjust the height of the line
  margin: 0 10px;
}
.lineStyleSmall {
  border-left: 1.5px solid #dcdfe3;
  // Defines the vertical line
  height: 8px; // Adjust the height of the line
  margin: 0 10px;
}

.lendio-title-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.lendio-title {
  color: #323b4b;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  align-items: baseline;
}

.grey-divider-line-lendio {
  display: flex;
  width: 100%;
  border-top: 1px solid #dcdfe3;
  padding-bottom: 25px;
}
.lendio-title-amount {
  color: #323b4b;
  font-weight: bold;
  font-size: 38px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lendio-subtitles {
  color: #8a94a6;
  font-size: 16px;
  line-height: 18.6px;
  font-weight: 400;
}
.lendio-subtitles-main {
  color: #8a94a6;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.6px;
  font-weight: 900;
}
.lendio-subtitle-amounts-main {
  color: #323b4b;
  font-size: 30px;
  line-height: 18.6px;
  font-weight: 800;
  margin-top: -10px;
}
.lendio-subtitle-amounts {
  color: #323b4b;
  font-size: 18px;
  line-height: 18.6px;
  font-weight: 500;
  margin-top: -10px;
}
.lendio-subtitle-and-amounts-main-wrapper {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.lendio-subtitle-and-amount-wrapper {
  // background-color: red;
  display: flex;
  flex-direction: column;
  // width: 90px;
  // height: 50px;
}
.credit-number-label {
  font-weight: normal;
  font-size: 16px;
  line-height: 155%;
  color: #323b4b;
  opacity: 0.7;
}

.credit-number {
  font-size: 32px;
  line-height: 125%;
  color: #7265ad;
  letter-spacing: 0.015em;
  margin-top: 5px;
  font-family: 'airbnb-cereal-medium';
}
.credit-amount-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loan-button-dashboard-container {
  margin-top: 30px;
}
#funding-status-text {
  color: $typography-text-2;
  //  font-family: 'airbnb-cereal-medium';
  font-size: 36px;
  line-height: 125%;
  letter-spacing: 0.015em;
  margin-top: 10px;
  text-align: center;
  font-family: 'causten-bold';
}
#funding-status-icon {
}
#funding-status-header-wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 538px;
  max-width: 100%;
}
#funding-status-header-wrapper-under-review {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 538px;
  max-width: 100%;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 45px;
  margin-bottom: 45px;
}
.application-status-subtitle {
  color: $typography-text-3;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.035em;
  text-align: center;
  margin-top: -10px;
  margin-bottom: -20px;
}
.schedule-container {
  // background-color: #FFFFFF;
  //border-radius: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 430px;
  margin-bottom: 30px;
  max-width: 100%;
}
.under-review-additional-text {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.015em;
  color: #323b4b;
  line-height: 155%;
  letter-spacing: 0.035em;
  text-align: center;
}
.phone-number {
}
.phone-icon-under-review {
  margin-bottom: -3px;
  margin-right: 10px;
}
.timebutton {
  background: #653494;
  border: 1px solid #653494;
  color: white;
  display: flex;
  height: 48px;
  padding: 12px 25px 12px 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 60px;
}
.timecanclebutton {
  background: white;
  border: 1px solid #dcdfe3;
  color: #dcdfe3;
  display: flex;
  height: 48px;
  padding: 12px 25px 12px 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 60px;
  margin: 10%;
}
.calandr {
  color: white;
  background: #653494;
}
