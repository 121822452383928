.enroll-main-wrapper {
  //background-color: red;
  width: 706px;
}

.enroll-now-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #38bb9f;
}
.enroll-explanation {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: #653494;
  margin-top: -15px;
}
// .enroll-header-wrapper {
//   height: 160px;
//   background-color: yellow;
//   display: flex;
// }

.benefits-list-style {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  //text-align: left;
  color: #1b284b;
  margin-left: 20px;
}
.benefits-wrapper {
  width: 345px;
  height: 240px;
  top: 376px;
  left: 300px;
  padding-bottom: 70px;
}
.green-check-style {
  z-index: 2;
}
#benefits-check-circle {
  width: 32px;
  height: 32px;
}

.approval-icon-and-text-wrapper {
  display: flex;
  flex-direction: row;
  width: 350px;
  height: 50px;
  align-items: center;
}
.info-boxes-wrapper {
  width: 646px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}
.info-box {
  width: 210px;
  height: 72px;
  top: 678px;
  left: 300px;
  padding: 16px, 14px, 15px, 16px;
  border-radius: 10px;
  background-color: #200849;
}
#info-boxes-circle {
  width: 24px;
  height: 24px;
  background: #38bb9f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Use percentage for border-radius to ensure a perfect circle */
  /* Specify border color and use 'solid' for a filled border */
  margin-top: -10px;
  margin-left: 15px;
}
.image-container {
  display: block;
}
.image-container-two {
  display: block;
  margin-top: -6px;
  margin-left: -24px;
}
.circle-content-style {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}
.info-box-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-left: 15px;
  margin-top: 2px;
}
.info-box-subtitle {
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 15px;
  margin-top: -20px;
}
// .info-box-word-wrapper {
//   background-color: red;
//   display: flex;
//   flex-direction: column;
//   margin-top: -10px;
//   height: 72px;
// }
.info-box-text-and-icon-wrapper {
  display: flex;
  flex-direction: row;
  width: 94%;
  //background-color: red;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
}

.purple-info-container {
  width: 646px;
  height: 279px;
  top: 799px;
  left: 300px;
  border-radius: 20px;
  background: #653494;
  margin-top: 40px;
  padding-top: 40px;
  padding-left: 30px;
}
.icon-and-text-wrapper {
  background-color: #1b284b;
}
.purple-container-text {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 537px;
  height: 84px;
  top: 869px;
  left: 330px;

  color: #ffffff;
}
.star-icon {
  padding-left: 10px;
}
.payro-user {
  width: 130px;
  height: 19px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.payro-user-desc {
  width: 60px;
  height: 17px;
  top: 1019px;
  left: 420px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -10px;
}
.user-and-desc-wrapper {
  display: flex;
  justify-content: space-between;
  width: 220px;
}
#white-circle {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Use percentage for border-radius to ensure a perfect circle */
  /* Specify border color and use 'solid' for a filled border */
  margin-top: -34px;
  margin-left: 41px;
  z-index: 2;
}
.trusted-text-style {
  width: 520px;
  height: 36px;
  top: 1128px;
  left: 303px;
  opacity: 0.8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #8c99b0;
}
