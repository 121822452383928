.submit-mfa-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.resend-code-section {
  color: black;
  font-size: 12px;
}
.resend-code-text {
  margin-bottom: 5px;
  margin-left: 5px;
  color: #4e5d78;
  font-weight: bold;
  font-family: "airbnb-cereal-medium";
  cursor: pointer;
}

#mfa-code-page-wrapper {
  width: 521px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#bad-phone {
  color: grey;
  text-align: center;
  background-color: #fff1d9;
  border-radius: 18px;
  padding: 8px;
}
