$primary-purple-700: #653494;
$primary-purple-800: #4c2d6a;
$primary-purple-900: #1a1244;
$primary-purple-background: #f4f2fa;
$typography-text-1: #2f353b;
$typography-text-2: #323b4b;
$typography-text-3: #4e5d78;
$typography-text-4: #8a94a6;
$accent-success: #21b17d;
$accent-success-800: #00834f;
$accent-waring-800: #ff9a3d;
