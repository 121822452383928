@import '../../../common-styles.scss';

.payroll-record {
  display: flex;
  margin-top: 7px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.payroll-record-label {
  text-align: left;
  font-size: 11px;
  margin-top: 17px;
}

.we-prestandard {
  margin-top: 50px;
  margin-bottom: 30px;
}

.date-and-cash {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.finance-numbers {
  margin-top: 5px;
}

// .funding-amount {
//   grid-column-start: 1;
//   grid-column-end: 2;
//   padding-right: 20px;
// }

.line-of-credit {
  color: #8a94a6;
  grid-column-start: 2;
  grid-column-end: 3;
  padding-left: 21px;
}

#line-of-credit-label {
  font-size: 12px;
  line-height: 155%;
  margin-bottom: -12px;
}

#line-of-credit-number {
  font-size: 16px;
  line-height: 21px;
}

.line-of-credit-bubble {
  margin-top: 30px;
}

.amount-you-want-to-finance-label {
  color: #2f353b;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.015em;

  margin-bottom: -6px;
  margin-top: 40px;
}

#available-credit-label {
  color: $typography-text-4;
  letter-spacing: 0.045em;
  font-size: 12px;
  line-height: 155%;
  font-weight: 400;
}

#available-credit-amount {
  color: $typography-text-4;
  letter-spacing: 0.015em;
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  font-weight: bold;
}

.payroll-to-fund-step {
  color: $typography-text-1;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.015em;
}

#amount-you-want-to-finance-label {
  margin-bottom: 0px;
}

#verify-payroll #request-funding-title-wrapper.more-funding {
  margin-bottom: -4px;
}

#payroll-verified {
  text-align: center;
  margin-top: -13px;
}

#no-future-payroll {
  color: #ff9a3d;
  font-size: 14px;
  background: rgba(255, 154, 61, 0.1);
  padding: 9px;
  border-radius: 6px;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.agreement {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Agreebutton {
  display: flex;
  height: 48px;
  padding: 12px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 60px;
  background: var(--accent-succes-succes-800, #00834f);
  border: 0;
  color: #fff;
}
.agreementheader {
  display: flex;
  height: 34px;
  justify-content: center;
  color: #323b4b;
}
.agrementbotom {
  display: flex;
  // height: 34px;
  justify-content: center;
  // margin-top: 30px;
}
.sign-agrementbotom {
  margin-top: 30px;
}
#clickingagrement {
  color: #8e8e8e;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#contract-text-new {
  height: 400px;
  max-height: 400px;
  overflow: auto;
  padding-bottom: 30px;
}
#contract-text-new h1 {
  font-size: 14px;
  // margin-top: 8px;
  // margin-bottom: 2px;
  font-weight: 100;
  padding: 2px 10px;
  background-color: #6d7a8545;
}
#contract-text-new h3 {
  display: grid;
  margin: 10px 10px 2px 10px;
  font-size: 12px;
  font-family: 'Times New Roman';
  background-color: #dee1e6;
  font-weight: 100;
}
#contract-text-new h5 {
  display: grid;
  margin: 10px 10px 2px 10px;
  font-size: 13px;
  font-family: 'Times New Roman';
  font-weight: 100;
}
#contract-text-new p {
  font-size: 10px;
  margin: 2px 0px 2px 0px;
  font-family: 'Times New Roman';
}
.bfr-img {
  padding-right: 10px;
}
.new-btn-c {
  background: transparent;
  color: #653494;
}
.new-btn-c div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
