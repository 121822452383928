.profile-icon {
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 6px;
}

.arrow-icon {
  margin-top: 15px;
}

#circle {
  width: 48px;
  height: 48px;
  background: #189a64;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
}
#circle-edit {
  width: 78px;
  height: 78px;
  background: #189a64;
  border-radius: 50%;
  align-items: center;
}
.user-letter {
  color: #ffffff;
  font-family: 'airbnb-cereal-light';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 22px;
  /* display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  margin-top: 5px; */
}
.user-letter-edit {
  color: #ffffff;
  font-family: 'airbnb-cereal-light';
  font-style: normal;
  font-weight: 900;
  font-size: 35.8158px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 26px;

  margin-top: 28px;
}
#edit-link {
  padding-left: 5px;
}
.dropdown-label-style {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.035em;
  color: #323b4b;
}
.dropdown-label-style:hover {
  color: #4c2d6a;
  opacity: 0.5;
}

.account-dropdown-username {
  font-family: 'Causten';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.015em;
  color: #323b4b;
}
.account-dropdown-email-username-wrapper {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 30px; */
}
.account-dropdown-email {
  font-family: 'Causten';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.035em;
  color: #4e5d78;
  margin-top: -7px;
}
.profile-menu-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px;
}
.profile-menu-option-logout {
  display: flex;
  border-top: 1px solid #f4f4f4;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.profile-option-icon-wrapper {
  width: 20px;
  display: flex;
  justify-content: center;
}
.dropdown-content {
  position: absolute;
  right: 5px;
  background-color: #ffffff;
  border: 1px solid #dcdfe3;
  width: 286px;
  height: 455px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-top: 10px;
  padding-left: 10px;
  justify-content: space-evenly;
  margin-left: 10%;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}
.dropdown-content a {
  color: #8a94a6;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  padding: 12px 16px;
  text-decoration: none;
}
.dropdown-content :hover a {
  color: #653494;
  border-left: none !important;
}

.icon {
  margin-left: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 600px) {
  /* .dropdown-content {
    width: 112px;
    margin-left: -30px;
  } */
  .profile-icon {
    padding-left: 10px;
  }
  /* #progress-bar-wrapper {
    margin-left: 80px;
  } */
}
