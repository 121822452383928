@import '../../../common-styles.scss';

#request-more-outer-wrapper {
  background-color: white;
  border-radius: 20px;
  // width: 498px;
  width: 498px;
  position: relative;
  max-width: 100%;
  margin: auto;
}
#request-more-outer-wrapper-funding-amount {
  background-color: #ffffff;
  box-shadow: 0px 5px 25px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  // width: 498px;
  width: 498px;
  position: relative;
  max-width: 100%;
  margin: auto;
}
#request-more-outer-wrapper-confirm {
  background-color: #ffffff;
  box-shadow: 0px 5px 25px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  width: 523px;
  position: relative;
  max-width: 100%;
  margin: auto;
}
#request-more-outer-wrapper-funded {
  background-color: #ffffff;
  box-shadow: 0px 5px 25px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  // width: 498px;
  width: 560px;
  position: relative;
  max-width: 100%;
  margin: auto;
}
#request-more-outer-wrapper-failed {
  background-color: #ffffff;
  //box-shadow: 0px 5px 25px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  // width: 498px;
  width: 560px;
  position: relative;
  max-width: 100%;
  margin: auto;
}
#request-more-outer-wrapper-no-available-credit {
  background-color: white;
  border-radius: 20px;
  width: 498px;
  position: relative;
  max-width: 100%;
  margin: auto;
  padding-bottom: 50px;
}
#ledger-home-container {
  padding: 0px 3px 3px 3px;
  margin: auto;
  width: 1000px;
  max-width: 100%;
}

#no-repayments-wrapper {
  padding: 10px;
  text-align: center;
  margin-bottom: 150px;
}

#no-repayments-image {
  width: 350px;
  max-width: 100%;
  height: auto;
}
#no-repayments-title {
  color: $typography-text-2;
  letter-spacing: 0.015em;
  font-family: 'causten-regular';
  line-height: 125%;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #323b4b;
}
.no-repayments-subtitle {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 155%;
  margin-top: -25px;
  letter-spacing: 0.035em;
  color: #4e5d78;
}

.user-first-name {
  font-family: 'causten-bold';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 155%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  /* Primary/Purple - 800 */

  color: #4c2d6a;
}
.status-red {
  border-radius: 6px;
  padding: 1px;

  width: 25px;
  height: 18px;
  left: 5px;
  top: 25.5px;

  color: #ff2b2b;
  background: #fde8e7;
  margin-left: 95px;
  margin-top: -34px !important;
  margin-right: 155px;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}
.status-green {
  color: #17b794;
  background: #edf7f0;
  border-radius: 6px;
  padding: 1px;
  width: 35px;
  height: 18px;
  left: 5px;
  top: 25.5px;
  margin-left: 88px;
  margin-top: -34px !important;
  margin-right: 155px;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}
.status-green-ledger-detail {
  color: #17b794;
  background: #edf7f0;
  border-radius: 6px;
  padding-left: 8px !important;
  width: 35px;
  height: 18px;
  left: 5px;
  top: 25.5px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}

.status-red-ledger-detail {
  color: #ff2b2b;
  background: #fde8e7;
  border-radius: 6px;
  padding-left: 8px !important;
  width: 35px;
  height: 18px;
  left: 5px;
  top: 25.5px;
  margin-left: 275px;
  margin-top: -34px !important;
  margin-right: 155px;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}

.status-paid {
  border-radius: 5px;
  padding: 5px;
  padding-left: 5px !important;
  width: fit-content;
  height: 25px;
  left: 5px;
  top: 25.5px;
  color: #17b794;
  background: #edf7f0;
  // margin-left: 35px;
  margin-right: 55px;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 155%;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}
.status-active {
  border-radius: 5px;
  margin-left: 5px !important;
  padding-left: 6px !important;
  width: 50px;
  height: 25px;
  left: 5px;
  top: 25.5px;
  color: #ff754c;
  background: #fff3dc;

  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 155%;
  display: flex;
  align-items: center;
  letter-spacing: 0.045em;
}

.status-pending {
  border-radius: 5px;
  padding: 6px !important;
  padding-left: 7px !important;
  width: fit-content;
  height: 25px;
  // margin-left: 10px;
  margin-left: -18px;
  top: 25.5px;
  color: #653494;
  background: #f4f2fa;
  // margin-left:24px;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  display: flex;
  flex-direction: row;
  align-items: center;
  letter-spacing: 0.045em;
}
.details {
  color: #653494 !important;
  display: block;
}
.details:hover {
  cursor: pointer;
  display: block;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 155%;
  text-decoration: none !important;
  margin-left: 5px;

  letter-spacing: 0.035em;
  color: #653494 !important;
}
.loan-id-image-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60px;
  justify-content: space-between;
}
@media screen and (max-width: 900px) {
  #no-repayments-image {
    width: 200px;
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  #no-repayments-title {
    color: $typography-text-2;
    letter-spacing: 0.015em;
    font-family: 'airbnb-cereal-medium';
    font-size: 28px;
    line-height: 125%;
  }
}

#repayments-container {
  background-color: white;
  width: 1160px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 20px;
  border-radius: 20px;
  padding-top: 8px;
  box-shadow: none;
}
#repayments-container-mobile-view {
  display: none;
}

@media only screen and (min-width: 600px) {
  #repayments-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
}

.repayment-wrapper {
  display: grid;
  grid-template-columns: 14% 18% 14% 14% 14% 14% 2%;
  box-shadow: inset 0px -1px 0px #e9e9e9;
  padding-top: 6px;
  height: 76px;
  flex-direction: row;
  align-items: center;
  padding: 4px;
}

// .loan-id {

//   // // display: flex;
//   // flex-direction: row;
//   // align-items: center;
// }
.loan-image {
  margin-left: -6px;
  cursor: pointer;
}
.details-image {
  // margin-top: -5px;
  margin-bottom: -7px;
  margin-left: 12px;
  cursor: pointer;
}
.repayment-col.outstanding-balance {
  padding: 4px;
  color: #d30808;
  font-family: 'causten-regular';
  // padding-left: 38px;
}
.repayment-col.payments-made {
  padding: 4px;
  color: #323b4b;
  font-family: 'causten-regular';
  // margin-left:-30px;
  margin-top: 8px;

  height: fit-content;
}
// .date-and-download-icon-wrapper {
//   background-color: red;
//   width: 170px !important;
//   display: flex;
//   flex-direction: row;
// }
.repayment-col.outstanding-balance-active {
  padding: 4px;
  color: #d30808;
  font-family: 'causten-regular';
  // padding-left: 24px;
  // margin-left: 2%;
}
.repayment-col.outstanding-balance-zero {
  padding: 4px;
  padding-left: 8px;
  color: #323b4b;
  font-family: 'causten-regular';
  // margin-left:-80px;
}
// .funding-amount {
//   padding-left: 54px !important;
// }
// .funding-amount-active {
//   // padding-left: 5px !important;

//   margin-left: 60px !important;
// }
// .funding-amount-complete {
//   margin-left: 36px !important;
// }
// .date-funded {
//   padding-left: 80px !important;
// }
// .date-funded-complete {
//   padding-left: 50px !important;
// }
// .date-funded-active {
//   padding-left: 90px !important;
// }
.repayment-info-item {
  padding: 4px;
}
// .payback-date {
//   padding-left: 40px !important;
// }
// .payback-date-active {
//   padding-left: 20px !important;
// }

#still-in-repayment-title {
  font-size: 20px;
  line-height: 26px;
  margin: 15px;
  margin-top: 35px;
  letter-spacing: 0.015em;
  color: $typography-text-2;
  font-family: 'causten-regular';
  font-weight: bold;
}

.repayment-subtitle {
  font-size: 18px;
  line-height: 26px;
  margin: 8px;
  margin-top: -5px;
  letter-spacing: 0.015em;
  color: #653494;
  font-family: 'causten-regular';
  // font-weight: bold;
}
#still-in-repayment-header {
  display: flex;
  background-color: #fafbfc;
  box-shadow: inset 0px -1px 0px #e9e9e9;
  padding-top: 6px;
  height: 50px;
  flex-direction: row;
  align-items: center;
}
#paid-up-repayment-header {
  display: flex;
  background-color: #fafbfc;
  box-shadow: inset 0px -1px 0px #e9e9e9;
  padding-top: 6px;
  height: 50px;
}
// #paid-up-repayment-header {
//   display: flex;
//   background-color: #fafbfc;
//   box-shadow: inset 0px -1px 0px #e9e9e9;
// }
.repayment-col {
  width: 185px;
  margin: 3px;
  padding: 8px;
  font-size: 14px;
  line-height: 155%;
  color: $typography-text-2;
  font-family: 'airbnb-cereal-light';
}
.repayment-col-active {
  width: 120px;
  margin: 3px;
  padding: 8px;
  font-size: 14px;
  line-height: 155%;
  color: $typography-text-2;
  font-family: 'airbnb-cereal-light';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

#still-in-repayment-header {
  .repayment-col {
    width: 200px;
    margin: 3px;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;

    font-family: 'airbnb-cereal-medium';
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: #4e5d78;
  }
}
.outstanding-header {
  margin-left: 50px !important;
}

#paid-up-repayment-header {
  .repayment-col {
    width: 200px;
    margin: 3px;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;

    font-family: 'airbnb-cereal-medium';
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: #4e5d78;
  }
}

#paid-up-title {
  margin: 15px;
  margin-top: 35px;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.015em;
  color: $typography-text-2;
  font-family: 'causten-regular';
  font-weight: bold;
}

#close-request-more {
  margin: 15px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  margin-left: -2%;
}

#open-request-funding-wrapper {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  #ledger-home-container {
    background-color: #e5e5e5;
  }

  #repayments-container {
    display: none;
  }
  #repayments-container-mobile-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .repayments-container-mobile-view-card {
    display: flex;
    flex-direction: column;
    padding: 20px;

    max-width: 96%;
    width: 385px;
    height: 203px;
    margin: 20px;

    background: #ffffff;
    /* Shadow - 2 */

    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  .repayment-col.outstanding-balance-active {
    margin-left: -10px;
  }

  .details {
    color: #323b4b !important;
  }
}

#on-hold-wrapper {
  color: #d30808;
  background-color: white;
  padding: 10px 5px;
  border-radius: 20px;
  width: 600px;
  max-width: 100%;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}

#on-hold-text-title {
  font-size: 20px;
  line-height: 26px;
  margin: 15px;
  letter-spacing: 0.015em;
}
#no-available-credit-text-title {
  font-size: 20px;
  line-height: 26px;
  margin: 15px;
  letter-spacing: 0.015em;
  margin-left: auto;
  margin-right: auto;
  color: #4e5d78;
}
.Activerepayment-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 1160px;

  max-width: 100%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  padding-top: 25px;
  padding-left: 19px;
  padding-bottom: 20px;
  padding-right: 19px;
}
.Active-Payroll{
  color: var(--typhograpy-text-2, #323B4B);
  /* Heading / Bold / H6 */
  font-family: Causten;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.Advance-repayment-heading{
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-size: 14px;
  line-height: 155%;
  color: #323b4b;
  font-family: "airbnb-cereal-light";
}
.still-in-repayment-header {
  display: flex;
  background-color: #fafbfc;
  box-shadow: inset 0px -1px 0px #e9e9e9;
  padding-top: 6px;
  height: 50px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  font-family: "airbnb-cereal-medium";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #4e5d78;
}
.still-in-repayment-data {
  display: flex;
  background-color: #fafbfc;
  box-shadow: inset 0px -1px 0px #e9e9e9;
  padding-top: 6px;
  height: 50px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  font-family: "airbnb-cereal-medium";
  font-style: normal;
  // font-weight: bold;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #4e5d78;
}
.ActiveRepayment-col{
  display: flex;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  font-family: "airbnb-cereal-medium";
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.015em;
  text-transform: capitalize;
  color: #4e5d78;
}
.ActiveRepayment-col-simple{
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  color: #323B4B;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 21.7px */
  letter-spacing: 0.49px;
}
.Details-col{
  color: #653494;
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 21.7px */
  letter-spacing: 0.49px;
}
.ActiveRepayment-colred{
  color:#d30808;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-size: 14px;
  line-height: 16px;
  font-family: "airbnb-cereal-medium";
  font-style: normal;
  // font-weight: bold;
  letter-spacing: 0.015em;
  text-transform: capitalize;
}
.ActiveRepayment-simple{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: #323B4B;
  width: 100%;
  margin: 3px;
  padding: 8px;
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: 0.49px;
}
  .ActiveRepaymentarr-col{
    display: flex;
    // justify-content: space-between;
    width: 100%;
    margin: 3px;
    padding: 8px;
    font-size: 12px;
    line-height: 16px;
    font-family: "airbnb-cereal-medium";
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.015em;
    text-transform: capitalize;
    color: #4e5d78;
  }
  .progress-barcontainer{
    max-width: 100%;
    height: 4px; 
    display: flex;
    margin-top: 10px; 
    align-items: center; 
    background-color: #e7e7e7; 
    border-radius: 14px;
  }
  .repayment-infoitemgreen{
    color: #17B794;
    background-color: #E0F5F091;
  }
  .repayment-infoitemred{
    color: #FF2B2B;
    background-color: #FDE8E7;
  }
