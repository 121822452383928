.spinner {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}
#full-page-loader-wrapper {
  background-color: #f5f5f5;
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.full-page-loader-wrapper-content {
  background-color: #fefefe !important;
  border: 1px solid #888;
  margin: auto;
  padding: 20px 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px;
  width: 476px;
  max-width: 100%;
  border-radius: 20px;
  margin-bottom: 20px;
}
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  width: 350px;
  margin: auto;
  text-align: center;
  padding: 0px;
  padding-top: 5px;
  position: unset;
}
.loader-message {
  color: #653494;
  font-family: 'causten-regular';
}
