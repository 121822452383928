.footer-container {
  border-radius: 0px;
  background-color: #2f353b;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 335px;
  padding: 40px;
}

/* .actual-logo { */
  /* max-width: 113px;
  margin-bottom: 20px; */
/* } */

.footer-text {
  width: 90%;
  max-width: 100%;
}
@media only screen and (max-width: 1000px) {
  .footer-text {
    width: 100%;
    max-width: 100%;
  }
}

.actual-text {
  /* font-family: 'airbnb-cereal-light'; */
  font-family: "causten";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 155%;
  letter-spacing: 0.045em;
  color: #fafafa;
}

/* .bold-container {
} */

.bold-text {
  color: #8a94a6;
  /* font-family: 'airbnb-cereal-medium'; */
  font-family: "causten";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 125%;
  letter-spacing: 0.015em;
  margin-top: 0px;
}

.purple-background-container {
  background: radial-gradient(87.68% 87.68% at 95.36% 14.36%, #653494 0%, #f3acff 55.86%);
  mix-blend-mode: normal;
  opacity: 20%;
  filter: blur(150px);
}

@media only screen and (max-width: 1450px) {
  .purple-background-container {
    margin-left: 300px !important;
    max-width: 100% !important;
    filter: blur(100px) !important;
  }
}
#legal-policy-links {
  display: flex;
  flex-direction: column;
  font-family: "causten";
  font-style: normal;
  font-weight: normal;
  line-height: 155%;
  letter-spacing: 0.045em;
  color: #fafafa;
  margin-bottom: 20px;
}
#legal-policy-links p {
  font-size: 16px;
  color: #e1c2f3;
  font-weight: bold;
  text-decoration: none;
}
#legal-policy-links a {
  font-size: 14px;
  color: #fafafa;
  text-decoration: none;
}
#legal-policy-links a:hover {
  font-size: 16px;
  font-weight: bold;
  color: #e73b91;

  text-decoration: none;
}

#footer-bottom {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

@media only screen and (max-width: 1000px) {
  #footer-bottom {
    display: flex;
    flex-direction: column;
  }
}


#report-an-issue {
  background-color: white;
  width: 500px;
  max-width: 100%;
  padding: 5px;
  border: 1px solid rgb(223, 36, 36);
  border-radius: 6px;
  color: rgb(126, 22, 22);
}

#main{
  
  color: #fafafa;
  background-color: #200849;
}
#spc{
  letter-spacing: 0.060em;
}
#spce{
  letter-spacing: 0.060em;
}
#w-50{
  float:left;
  width: 50%;
  padding-left: 120px;
  padding-top: 30px;
}
@media only screen and (max-width: 670px) {
  #w-50 {
    text-align: center;
    width: 100%;
    padding-left: 0px;

  }
}
#w-25{
  float:left;
  width: 25%;
  padding-top: 30px;
}
@media only screen and (max-width: 670px) {
  #w-25 {
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
}
p.normal-txt {
  margin: 0 0 10px 0;
  color: #afa9a9;
}
ul.foot-items {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
ul.foot-items li.foot-item {
  margin-bottom: 10px;
}
ul.foot-items li.foot-item a{
  text-decoration: none;
  color: #afa9a9;
}
/* #cab{
  float: left;
  width: 25%;
  padding-top: 30px;
} */
/* #siz{
  height: 10px;
  width: 10px;
} */
#s-icon{
  height: 10px;
  max-width: 100%;
}
ul.social-icons {
  padding: 0;
  margin: 0;
  list-style: none;
}
li.s-icon {
  display: inline-block;
  width: 40px;

  margin-right: 0px;
}
li.s-icon img.siz {
  margin-top: 30px;
  width: 70%;
  padding-left: 0%;
  margin-left: 0%;
}
hr.foot {
  width: 90%;
  /* float: left; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marg{
  margin-left: 75px;
}
@media only screen and (max-width: 575px) {
  .marg {
    margin-left: 30px; 
  }
}
.titl{
    display: flex;
    align-items: center;
    justify-content: flex-start
}
.head{
  margin-top: 60px;
}


.proudBox{
  margin-top: 30px;
}
.leftmr{
  margin-left: 20px;
}
/* social-icons {
  max-width: 100px;
} */

/* New footer CSS */
footer.new-footer{background:#200849; padding:50px 0 20px; 
  /* margin-top: 40px; */
}
footer.new-footer .footer-main {margin-bottom:50px;}
.new-footer .container{display:flex;}
.footerSrt-dtl p{font-size:20px; font-weight:500; line-height:1.4; letter-spacing:0em; text-align:left; color:#fff;}
.new-footer .social-icon li a{height:40px; width:40px; border-radius:40px; -webkit-border-radius:40px; border:1px solid #fff; display: flex; align-items:center; justify-content:center; transition:all 0.4s ease; -webkit-transition:all 0.4s ease;}
.new-footer .social-icon li a:hover{background:#fff;}
.new-footer .social-icon li a:hover svg path{transition:all 0.4s ease; -webkit-transition:all 0.4s ease;}
.new-footer .social-icon li a:hover svg path{fill:#200849 !important;}

.footerSrt-dtl{max-width:250px; margin-top:44px;}
.new-footer .copyright-text{width:100%; color:#C6CAE180;}
.new-footer .copyright-text .copyText small{color:rgba(198, 202, 225, 0.5);}
.copy-n-menu{display:flex; gap:40px; justify-content:space-between;}
.policy-menu a{color:rgba(198, 202, 225, 0.5); padding:0 15px; font-size:16px; text-decoration: none;}
.policy-menu a:last-child{padding-right:0;}
.policy-menu a:first-child{padding-left:0;}
.policy-menu a:hover{color:rgba(231, 232, 233, 1);}

.new-footer .social-icon{margin-top:26px;}
.new-footer .social-icon .social-links{display:flex; gap:15px;list-style-type: none;padding: 0;margin: 0;}
.copyright-text { line-height: 20px;}
a.enroll-Btn {text-decoration: none;}
.copyText small {font-size: 16px;}
.new-footer .footer-main .footer-link-block .footer-link-heading{font-size:20px; line-height:1; margin-bottom:16px; font-weight:600; display:block; color:#fff;}
.new-footer .footer-main .footer-link-block ul.footer-links {padding: 0;}
.new-footer .footer-main .footer-link-block ul.footer-links li {list-style-type: none;padding: 0;}
.new-footer .footer-main .footer-link-block ul.footer-links li a{font-size:16px; line-height:1; padding:8px 0; font-weight:600; display:inline-block; color:#fff;text-decoration: none;}
.new-footer .footer-main .footer-link-block ul.footer-links li a:hover{color:rgba(255, 255, 255, .7);}


.footer-enroll{text-align:center; max-width:276px;}
.footer-enroll h3{color:#fff; font-size:24px; font-weight:500; line-height:1.32; margin-top: 0; margin-bottom:30px; text-align:center;}
.footer-enroll a.enroll-Btn{width:auto; height:55px; padding:0 49px; border-radius:30px; margin-bottom:30px; background:#38BB9F; color:#fff; display:inline-flex; align-items:center; justify-content:center; font-size:20px; font-weight:600; line-height:1; gap:10px; transition:all 0.4s ease; -webkit-transition:all 0.4s ease;}
.footer-enroll a.enroll-Btn:hover{background:#653494;}
.footer-logo ul{display:inline-flex; gap:30px; align-items:center;list-style-type: none;padding: 0;margin: 0;}
.col-md-4{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
footer .footer-copyright-block .copyright-text {
  border-top: 1px solid rgba(231, 232, 233, 0.4);
  padding-top: 16px;
}
.container{
  width: 100%; 
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}
@media (min-width: 768px){
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 1366px){
  .container {
    max-width: 1350px;
  }
}


@media (max-width:1550.5px){
  .footer-enroll h3{font-size:22px;}
  .footer-enroll a.enroll-Btn{height:50px; padding:0 35px;}
  .footerSrt-dtl p{font-size:19px;}
  .social-icon ul li svg{height:18px !important; width:auto !important;}
}
@media (max-width:991.5px){
  .footer-logo img{max-width:150px;}
  .footerSrt-dtl{margin-top:30px;}
  .footerSrt-dtl p {font-size:18px;}
  .footer-enroll h3 {font-size:20px; margin-bottom: 20px;}
  .footer-enroll a.enroll-Btn{height:46px; padding:0 25px; font-size:17px;  margin-bottom:20px;}
  .footer-logo ul{gap:20px;}
  .new-footer .social-icon li a{height:35px; width:35px;}
  .social-icon ul li svg{height:16px !important;}
  .new-footer .footer-link-block{max-width:150px; margin:0 auto;}
  .footer-logo img{max-width:100%;}
}
@media (max-width:767.5px){
  .new-footer .container{flex-wrap:wrap;}
  .new-footer .container .col-md-4{max-width:50%; flex:0 0 50%;}
  .new-footer .container .col-md-4:last-child{max-width:100%; flex:0 0 100%; padding-top:30px;}
  .footer-enroll{margin:0 auto;}
  .footer-enroll {text-align:center; max-width:336px;}
  .new-footer .footer-link-block{float:right;}
  .footerSrt-dtl{margin-top:20px;}
  .footer-enroll h3 {margin-bottom:15px;}
  .new-footer .social-icon{margin-top:16px;}
  footer.new-footer .footer-main{margin-bottom:30px;}
  .footerSrt-dtl p{font-size:17px;}
  .copy-n-menu {gap:5px; justify-content:center; flex-wrap:wrap;}
}
@media only screen and (max-width: 575px){
  footer.new-footer  .footer-main{text-align:inherit;}
  .policy-menu a{font-size:14px;}
  footer .footer-copyright-block .copyright-text {text-align: center;padding-top: 0;border-top-color: transparent;}
}
@media only screen and (max-width:400px){
  footer.new-footer  .footer-main{text-align:center;}
  .new-footer .container .col-md-4{max-width:100%; flex:0 0 100%; padding-top:20px; border-bottom:1px solid #cccccc3d; padding-bottom:20px;}
  .new-footer .footer-link-block{float:none;}
  .footerSrt-dtl{max-width:100%;text-align:center;}
  .footerSrt-dtl p{text-align:center;}
  footer.new-footer .footer-main{margin-bottom:16px;}
  .new-footer .social-icon .social-links{display:inline-flex;}
  .new-footer .social-icon {margin-bottom:10px;}
  .new-footer .footer-main .footer-link-block .footer-link-heading{margin-top:7px;}
  .copy-n-menu{gap:0px;}
}


@media only screen and (max-width: 400px){
.new-footer .container .col-md-4 {
    max-width: 100%;
    flex: 0 0 100%;
    padding-top: 20px;
    border-bottom: 1px solid #cccccc3d;
    padding-bottom: 20px;
}
}

/* New footer CSS */