.bank-logo {
  max-width: 38px;
  margin-left: 15px;
  margin-right: 15px;
  height: auto;
}
/* .bank-icon {
  margin-left: 15px;
} */
.bank-logo-style {
  width: 40px;
  height: 40px;
  /* margin-right: 45px; */
}
.bank-name-and-icon-wrapper {
  display: flex;
  flex-direction: row;
  width: 170px;
  justify-content: space-evenly;
}
#connect-container #finicityConnectIframe {
  left: 25%;
  right: 25%;
  bottom: 25%;
  top: 25%;
  width: 50%;
  height: 70%;
  padding-bottom: 7px;

  max-width: 100%;
  position: fixed;
  border: 1px solid #e0ddf3;
  box-shadow: 6px 10px 17px grey;
  z-index: 3000;

  /* width: 615px;
    max-width: 100%;
    border-radius: 10px;
    left: auto;
    right: auto;
    /* top: 80px;
    margin: auto;
    bottom: auto; */
  /* margin-top: -325px;
    margin-left: -40px;
    height: 600px;
    /* height: 824px; */
  /* position: absolute;
    border: 1px solid #7165ad;
    box-shadow: 6px 10px 17px grey;
    z-index: 1000; */
}
.connected-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
  /* Primary / Purple - 50 */
  border: 1px solid #e0ddf3;
  box-sizing: border-box;
  border-radius: 10px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
  margin-bottom: 25px;
}
.plaid-logo {
  margin-left: 5px;
}
.plaid-check-circle {
  margin-right: 5px;
}
.plaid-check-circle.repeat-funding {
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: -5px;
}
.plaid-connected-words {
  font-family: "airbnb-cereal-book";
  display: inline;
}
.plaid-connected-words.repeat-funding {
  margin-top: 30px;
  font-style: bold !important;
  display: inline;
  font-size: 14px;
  /* color: #323B4B; */
  color: #653494;
  font-weight: 400px;
  align-items: center;
  margin-left: 68px;
}

.plaid-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
  border: 1px solid #e0ddf3;
  box-sizing: border-box;
  border-radius: 10px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
  margin-bottom: 25px;
  width: 100%;
  height: 55px;
  justify-content: center;
}

.plaid-wrapper-repeat-funding-not-connected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 35px;
  /* border: 1px solid #E0DDF3;; */
  box-sizing: border-box;
  border-radius: 10px;

  order: 1;

  flex-grow: 0;
  margin-top: -100px;
  margin-left: 210px;
  margin-bottom: 5px;
  width: 360px;
  background: #f4f2fa;
  box-shadow: 0px 25px 40px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
}
.button-wrapper {
  padding-left: 50px;
}
.bank-balance {
  font-family: "causten-regular";
  font-style: normal;
  font-weight: 400;

  line-height: 125%;
  margin-top: 10px !important;
  margin-left: 15px !important;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.015em;
  font-weight: 500;
  font-size: 34px;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;
  color: #2f353b;
  margin-top: 15px;
  font-weight: bold;
}
.full-wrapper-bank-name {
  padding-left: 40px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 300px;
}
.connect-button {
  margin-top: -55px;
  /* margin-left: 281px; */
  bottom: 15px;
}
.dashboard-no-connected-bank-wrapper {
  margin-top: 22px;
  margin-left: -5px;
}
.bank-balance-title {
  margin-left: 55px;
}
.connect-plaid-sec .finicity-wrapper {
  margin-bottom: 25px !important;
}
.main-input {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.c-title {
  color: var(--typhograpy-text-2, #323b4b);
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.24px;
  display: block;
  float: left;
}
.cnct-cancel {
  color: var(--typhograpy-text-3, #4e5d78);
  text-align: right;
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.21px;
  display: block;
  float: right;
  cursor: pointer;
}
.head-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.connect-manual {
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 0px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--grayscale-black-200, #f4f4f4);
}
.a-manual {
  color: var(--primary-purple-700-main, #653494);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
  cursor: pointer;
}

button.another.manual-sec {
  cursor: pointer;
  display: flex;
  height: 36px;
  padding: 8px 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #fff;
  border-radius: 60px;
  color: #653494;
  border: 1px solid #dcdfe3;
}
.another-add {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.manual-sec {
  color: var(--grayscale-black-900, #090b0c);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: 0.49px;
}
.finicity-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px 15px 10px;
  /* Primary / Purple - 50 */

  border: 1px solid #e0ddf3;
  box-sizing: border-box;
  border-radius: 10px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}
.finicity-api-words {
  margin: 0px 10px;
  font-size: 12px;
}

.finicity-right-arrow {
  width: 14px;
  height: 10px;
  margin-right: 14px;
}

.finicity-check-circle {
  margin-right: 5px;
}

.finicity-connected-words {
  font-family: "airbnb-cereal-book";
}

.disconnect {
  color: #ff2b2b;
  font-family: "airbnb-cereal-book";
  font-size: 11px;
  margin-left: auto;
}

.finicity-connect-text {
  font-size: 16.5px;
  font-family: "causten-regular";
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: #323b4b;
  flex: none;
  margin-top: -30px;
}

.finicity-subtitle {
  font-family: "causten-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 155%;
  letter-spacing: 0.045em;
  color: #8a94a6;
  margin-top: 42px;
  margin-left: -125px !important;
  flex: none;
  /* display: flex;
    flex-direction: row; */
}

#finicity-connected-wrapper {
  justify-content: flex-start;
  display: flex;
  width: 100%;
}

.finicity-connected-message {
  margin-left: 15px;
  font-weight: bold;
  font-family: "causten-regular";
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: #323b4b;
}
.chase-connected-message {
  font-weight: bold;
  font-family: "causten-regular";
  font-size: 14.5px;
  line-height: 150%;
  letter-spacing: 0.3px;
  color: #323b4b;
  margin-top: -1px;
}
.grey-line-wrapper {
  border-bottom: 1px solid #dcdfe3;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: top;
}
.bank_data .connected-account-container {
  margin: 0 0 10px 0;
  padding: 0;
}
.connected-account-container {
  display: flex;
  padding: 10px 12px 10px 24px;
  border: 1px solid #ad9bcb;
  border-radius: 10px;
  justify-content: space-evenly;
  margin-bottom: 10px;
  width: 100%;
  opacity: 0.5;
  margin-left: 15px;
  margin-bottom: 25px;
  height: 100px;
}
.integrations-bank-wrapper {
  margin-bottom: 40px;
}
.connected-account-container-integrations {
  display: flex;
  padding: 10px 12px 10px 24px;
  border: 1px solid #dcdfe3;
  border-radius: 10px;
  justify-content: space-evenly;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 25px;
  height: 88px;
  align-items: start;
  cursor: pointer;
  opacity: 0.7;
}
.connected-account-container-integrations.selected {
  display: flex;
  padding: 10px 12px 10px 24px;
  border-radius: 10px;
  justify-content: space-evenly;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 25px;
  height: 90px;
  align-items: start;
  opacity: 1;
  border: 1px solid #ad9bcb;
  background-color: #f4f2fa;
}
.dropdown-main-wrapper {
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
}
.select-bank-type-header-style {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.3px;
  /* color: #4c2d6a; */
  color: #323b4b;
}
.icon-and-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.connected-account-container-chase {
  /* width: 560px; */
  width: 100%;
  height: fit-content;

  align-items: center;
  /* border: 1px solid #f4f4f4; */
  border-radius: 12px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
#chase-details-wrapper {
}
.connected-account-container.selected {
  opacity: 1;
  border: 1px solid #ad9bcb;
  background-color: #f4f2fa;
}
.connected-account-container:hover {
  cursor: pointer;
}

.finicity-bank-name {
  margin: 4px;
  margin-left: -1px;
}
.chase-bank-name {
  color: #4e5d78;
  display: flex;
  justify-content: center;
}
.finicity-account-nickname {
  margin: 4px;
}
.finicity-account-4dots {
  font-size: 22px;
  margin-left: 3px;
  margin-right: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.015em;
  margin-top: 11.9px;
}
.finicity-account-last4 {
  margin: 4px;
  font-size: 18px;
  margin-left: -10px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.015em;
}

.finicity-account-link {
  background-color: transparent;
  color: #4c2d6a;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.035em;
  cursor: pointer;
  margin-right: 15px;
  margin-left: 40px;
  font-family: "causten-regular";
  width: 145px;
  /* justify-content: space-around;
   display: flex;
   flex-direction: row; */
}

.finicity-account-type {
  font-size: 12px;
  line-height: 35px;
  font-weight: normal;
  color: #4e5d78;
  letter-spacing: 0.045em;
  margin-top: -12px;
  /* identical to box height, or 19px */
  /* Typhograpy/Text - 3 */
}
#finicity-details-wrapper {
  display: flex;
  flex-direction: row;

  align-items: center;
}

.bank-name-number-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.015em;
  margin-top: 15px;
  height: 20px;
  color: #323b4b;
}
.bank-account-type-style {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  letter-spacing: 0.045em;
  color: #4e5d78;
}
@media only screen and (max-width: 1000px) {
  .finicity-account-link {
    font-size: 12px;
    margin-left: 15px;
    margin-right: 0px;
  }

  .accent-button.purple-white-variant {
    width: 160px;
  }

  .connected-account-container {
    width: 370px;
    padding: 8px 10px 8px 10px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 600px) {
  .finicity-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 20px;
    gap: 25px;
    width: 335px;
    height: 163px;
    background: #f4f2fa;
    border: 1px solid #e0ddf3;
    border-radius: 12px;
  }
}

@media only screen and (max-width: 600px) {
  #connect-container #finicityConnectIframe {
    left: 2%;
    right: 5%;
    bottom: 5%;
    top: 8%;
    width: 95%;
    height: 85%;
    max-width: 100%;
    position: fixed;
    border: 1px solid #7165ad;
    box-shadow: 6px 10px 17px grey;
    z-index: 1;
  }

  .plaid-wrapper-repeat-funding {
    margin-left: -114px;
    margin-top: 10px;
    margin-bottom: -15px;
  }
  .plaid-wrapper-repeat-funding-not-connected {
    margin-left: -114px;
    margin-top: 10px;
    margin-bottom: -15px;
    width: 315px;
    height: 110px;
  }
  #finicity-connected-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .finicity-connected-message {
    margin-right: 0px;
  }
  .finicity-connect-text {
    margin-top: 0px;
  }
  .finicity-subtitle {
    margin-top: -26px;
    margin-left: 0px !important;
    margin-bottom: -20px;
  }
  .button-wrapper {
    padding: 0px;
  }
}
.btn-handle button {
  margin: 0 auto;
}
.skipbtn {
  background-color: #653494;
  display: flex;
  height: 40px;
  padding: 10px 25px 12px 30px;
  justify-content: center;
  border-radius: 60px;
  border: 0px;
  align-items: center;
}
.skipcolor {
  color: white;
}
.bank_data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
