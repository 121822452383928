#scheduled-icon {
  padding-top: 110px;
  padding-bottom: 40px;
  margin-left: 120px;
  width: 310px;
  max-width: 100%;
  height: auto;
}

#scheduled-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  // padding-top: 38px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  margin: auto;
  width: 560px;
  height: 624px;
  background: #ffffff;
  box-shadow: 0px 35px 60px rgba(21, 21, 56, 0.03);
  border-radius: 20px;
}

.schedule-payment-close {
  top: 180px;
  left: 850px;
}

.scheduled-explanation {
  margin-top: -25px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 1600px) {
  .schedule-payment-close {
    top: 180px;
    left: 1130px;
  }
}

@media only screen and (max-width: 600px) {
  #scheduled-wrapper {
    width: 375px;
    height: 624px;
  }
  #scheduled-icon {
    width: 257px;
    margin-left: 60px;
  }

  .schedule-payment-close {
    top: 180px;
    left: 300px;
  }
}
