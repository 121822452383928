.avatar-buttons-wrapper {
  display: flex;

  justify-content: center;
  margin-bottom: 42px;
}
.buttons-wrapper {
  /* display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 580px;
}
.my-profile-name-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 580px;
  margin-bottom: 30px;
}
#update-email-phone-warning {
  border: 2px solid red;
  border-radius: 20px;
  padding: 20px;
  /* background-color: #2b2b2b; */
  color: black;
  font-weight: 900;
}
#profile-section-wrapper {
  width: 687px;
  padding-left: 100px;
  max-width: 100%;
}
#profile-section-wrapper-integrations {
  width: 687px;
  padding-left: 100px;
  max-width: 100%;
}
.profile-reset-password-wrapper {
  margin-top: 40px;
  border-top: 2px solid #dcdfe3;

  display: flex;
  flex-direction: row;

  justify-content: center;
}
.info-message-edit-profile-mobile {
  color: #323b4b;
  font-weight: 600;
}
.input-info-message-edit-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #323b4b;
  font-size: 12px;
  margin-top: -20px;
  margin-bottom: 20px;
  justify-content: end;
  margin-right: 15px;
}

.reset-password-profile-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px 14px;
  width: 275px;
  height: 46-px;
  margin-top: 20px;
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  text-align: center;
  letter-spacing: 0.015em;
  color: #7265ad;
}

.reset-password-profile-text:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 24px 14px;
  width: 275px;
  height: 46px;
  background: #f4f2fa;
  border-radius: 100px;
  cursor: pointer;
}
.uneditable-text {
  color: #ccd3cf !important;
}
@media screen and (max-width: 600px) {
  #profile-section-wrapper {
    /* padding-left: 65px;
    padding-right: 45px; */
    padding-left: 20px;
    justify-content: center;
  }
  #profile-section-wrapper-integrations {
    padding-left: 10px;
  }
  .my-profile-name-inputs {
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
  }
  .buttons-wrapper {
    flex-direction: column;
    max-width: 80%;
    margin-left: 22px;
  }
  .input-info-message-edit-profile {
    margin-right: 150px;
  }
  .avatar-buttons-wrapper {
    display: none;
  }

  .input-container.edit-profile-input {
    width: 95%;
  }
}

/* DOB CSS */

/* DOB CSS */
