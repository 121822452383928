.loan-timeline-circle {
  background: #f4f2fa;
  // width: 28px;
  // height: 28px;
  border-radius: 50%;
  padding: 2.5px;
  height: 20px;
  width: 20px;
  margin-left: -10px;
}

.loan-timeline-item {
  display: flex;
  height: 47px;

  align-items: center;
  margin-bottom: 27px;
}
