.funding-amount-and-avail-credit-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -30px;
}
.request-more-credit-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 25px;
  margin-top: 50px;
  width: 476px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.message-to-user {
  letter-spacing: 0.015em;
  color: #4e5d78;
  font-size: 12px;
  line-height: 26px;
}
.message-to-user-and-icon-wrapper {
  display: flex;
  flex-direction: row;
  width: 65%;
  justify-content: space-between;
  margin-top: -20px;
}
// #unable-to-request-credit-text-container {
//   background-color: red;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   align-content: center;
//   margin-left: auto;
//   margin-right: auto;
// }
.unable-to-request-credit-text-title {
  font-size: 20px;
  line-height: 26px;
  margin: 15px;
  letter-spacing: 0.015em;
  color: #4e5d78;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.verify-last-payroll-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.015em;
  font-family: 'airbnb-cereal-medium';
  color: #323b4b;
  //padding-left: 8px;
}
