#connect-plaid-page-wrapper {
  max-width: 500px;
  margin: auto;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 100px;
}

#connect-your-bank-title {
  text-align: center;
  margin-bottom: 35px;
}
