@import '../../../common-styles.scss';

.week-choices {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .week-choices {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

.repeat-funding-input-and-another-column {
  display: flex;
  align-items: center;
}

.week-choice {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 130px;
  width: 130px;
  padding-left: 10px;
  margin-bottom: 15px;
  padding: 20px;
  align-items: center;
}
.week-choiceNY {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 130px;
  width: 130px;
  padding-left: 10px;
  margin-bottom: 15px;
  padding: 20px;
  align-items: center;
  border: 1px solid #dcdfe3;
  border-radius: 5px;
}

.week-option-label {
  font-family: 'causten-regular';
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  color: $typography-text-2;
  letter-spacing: 0.3px;
}
.week-option-date {
  color: $typography-text-3;
  font-size: 12px;
  line-height: 155%;
  /* identical to box height, or 19px */

  letter-spacing: 0.045em;
}
.total-cost-label {
  font-family: 'airbnb-cereal-light';
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 155%;
  letter-spacing: 0.03em;
  color: #4e5d78;
  margin-top: 10px;
}

.interest-and-fee {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 155%;
  letter-spacing: 0.035em;
  color: #4c2d6a;
}

.unavailable .week-option-date {
  color: red;
}

.week-choice.purple-background-color .week-option-label {
  font-family: 'airbnb-cereal-medium';
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  color: $primary-purple-800;
  letter-spacing: 0.3px;
}

.week-choice.purple-background-color .week-option-date {
  color: $primary-purple-700;
  font-size: 12px;
  line-height: 155%;
  /* identical to box height, or 19px */

  letter-spacing: 0.045em;
}

.payroll-funding {
  // grid-column: 2 / 3;
  // grid-row: 1 / 2;
}

.total-interest {
  color: #2f353b;
  font-size: 14px;
}

.total-interest-val {
}

.one-time-processing {
  letter-spacing: 0.035em;
  font-size: 12px;
  line-height: 155%;

  /* Typhograpy/Text - 4 */

  color: #8a94a6;
}

.one-time-processing-val {
}

.your-numbers-divider {
  border-top: 1px solid #dcdfe3;
  margin-top: 30px;
  margin-bottom: 12px;
}

.total-repayment {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 155%;
  letter-spacing: 0.035em;
  color: #2f353b;
  text-align: center;
  margin-top: 15px;
}

.total-repayment-val {
  font-family: 'causten-bold';
  font-style: normal;
  font-size: 48px;
  line-height: 108%;
  text-align: center;
  letter-spacing: 0.015em;
  color: #653494;
}
.total-repayment-val-confirm {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  margin: 0;
  line-height: 125%;
  text-align: center;
  letter-spacing: 0.015em;
  color: #653494;
}

.interest-rate {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fixed-fee {
  font-family: 'airbnb-cereal-medium';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #adb4bd;
  border: 5px solid #adb4bd;
  border-radius: 3px;
}
.info-icon {
  margin-left: 10px;
  margin-top: 10x;
}

@media only screen and (max-width: 600px) {
  .interest-rate {
    background-color: white;
    color: $typography-text-4;
    font-size: 16px;
    line-height: 21px;
  }
}

#interest-rate-frequency {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  // line-height: 21px;
  display: flex;
  //align-items: center;
  color: #4e5d78;
}

.your-numbers-label {
  line-height: 155%;
  // color: #2F353B;
  letter-spacing: 0.035em;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.035em;
  color: #000000;
}
.your-numbers-label.one-time-processing {
  font-size: 12px;
  line-height: 155%;
  color: #4e5d78;
  letter-spacing: 0.035em;
  font-family: 'airbnb-cereal-light';
  font-family: 400;
  margin-top: -10px;
}

.your-numbers-label.one-time-processing.bold {
  font-weight: bold;
  font-size: 12px;
  line-height: 155%;
  color: #4e5d78;
  letter-spacing: 0.035em;
  margin-left: 4px;
  font-family: 'causten-bold';
}
.payroll-funding-values-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.your-numbers-val {
  // font-weight: 500;
  font-size: 16px;
  line-height: 155%;
  letter-spacing: 0.035em;
  // color: #323b4b;
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: normal;
  color: #000000;
  margin-left: 8px;
}

.interest-rate p {
  margin-bottom: 1px;
  text-align: center;
}

#interest-rate-number {
  font-family: 'causten-bold';
  font-size: 16px;
  line-height: 23px;
  display: inline;
  align-items: center;
  text-align: center;
  color: $typography-text-1;
  margin-right: 7px;
}

#interest-rate-type {
  color: $accent-success;
  font-size: 12px;
  line-height: 155%;
  margin-top: 10px;
  /* identical to box height, or 19px */

  letter-spacing: 0.045em;
  font-family: 'airbnb-cereal-medium';
}
#step-3-question {
  color: #2f353b;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.015em;
  font-family: 'airbnb-cereal-medium';
}

#open-guide-icon {
  margin-right: 5px;
}
#open-guide-icon.open-it {
  transform: rotate(180deg);
}

.total-repayment.more-funding {
  font-size: 12px;
  line-height: 155%;
  font-weight: 400;
  color: #4e5d78;
  text-align: center;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-top: 10px;
}
.total-repayment-val.more-funding {
  text-align: center;
  font-size: 24px;
  letter-spacing: 0.025em;
  font-weight: 800;
  font-family: 'causten-bold';
  margin: 0px;
  margin-bottom: 24px;
  color: #574b89;
}
.total-payback-wrapper-more-funding {
  margin-top: 30px;
}
@media only screen and (max-width: 600px) {
  .total-repayment.more-funding {
    font-size: 12px !important;
    line-height: 155%;
    color: #4e5d78;
    text-align: center;
    margin-bottom: 0px;
  }
  .total-repayment-val.more-funding {
    text-align: center;
    letter-spacing: 0.025em;
    margin: 0px;
    color: #574b89;
  }
}
.repeat-funding-input-and-another-column {
  margin-top: 20px;
}
#more-funding-repayment-date {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#payment-repayment-date .payro-select-wrapper {
  width: 422px;
}
#more-funding-repayment-date .payro-select-wrapper {
  width: 440px !important;
  margin-top: -20px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
  #more-funding-repayment-date .payro-select-wrapper {
    width: 200px;
  }
}
#more-funding-fixed-rate {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
#more-funding-fixed-rate p {
  margin: 0px;
}

#rate-line-1 {
  letter-spacing: 0.045em;
  color: #8a94a6;
  font-size: 12px;
  line-height: 155%;
}
#rate-line-2 {
  letter-spacing: 0.015em;
  color: #8a94a6;
  font-size: 16px;
  line-height: 21px;
}
@media only screen and (max-width: 600px) {
  .your-numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .your-numbers-label {
    font-size: 14px;
  }
  .your-numbers-val {
  }
  .total-repayment {
    font-size: 14px;
  }
  .your-numbers-label.one-time-processing.bold {
  }
  .your-numbers-label.one-time-processing {
    font-size: 12px;
  }
}

#processing-fee-section {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

// DOB
.advance-option {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
}
.advance-option-section {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  max-width: 50%;
  width: 50%;
  padding: 20px 20px 40px 20px;
  align-items: center;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid var(--primary-purple-50, #e0ddf3);
}

.top-selection {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--primary-purple-background, #f4f2fa);
}
.total-c,
.total-weekly-c {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.cost-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.rate-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cost-txt {
  color: var(--typhograpy-text-4, #8a94a6);
  font-family: Causten;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
}
.cost-amt {
  color: var(--primary-purple-700-main, #653494);
  font-family: Causten;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.6px;
}
span.rate {
  color: var(--accent-warning-warning-800, #ff9a3d);
  text-align: right;
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 0.56px;
}
span.rate-factor {
  color: #4e5d78;
  font-family: Causten;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 155%;
  letter-spacing: 0.3px;
}

.line-hint {
  color: #4e5d78;
  font-family: Causten;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 0.3px;
  margin: 0;
}
.section2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.top {
  color: var(--typhograpy-text-4, #8a94a6);
  font-family: Causten;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 13px */
}
.incl {
  color: var(--grayscale-black-600, #7d8997);
  font-family: Causten;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 100%; /* 10px */
  letter-spacing: 0.3px;
}
.Estimated {
  align-self: stretch;
}
.date {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}
.Estimated2 {
  color: var(--typhograpy-text-4, #8a94a6);
  font-family: Causten;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 150% */
}
.date2 {
  display: flex;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
}
.rate-factorcost {
  color: var(--typhograpy-text-2, #323b4b);
  /* Heading / Bold / H6 */
  font-family: Causten;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}

.bottom-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.bottom-total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.bottom-estimated {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.bottem-second {
  display: flex;
  width: 230px;
  // justify-content: center;
  align-items: baseline;
  gap: 6px;
}
.advance-option-section {
  display: flex;
  padding: 20px 20px 40px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
// .current-selection .bottembutton{
//   display: flex;
//   height: 48px;
//   padding: 12px 25px 12px 30px;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   gap: 8px;
//   border: 0;
//   border-radius: 60px;
//   background: var(--primary-purple-700-main, #653494);
// }
.Select {
  color: var(--white, #fff);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  /* Button/Medium Button */
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.35px;
  padding-right: 10px;
}
.advance-option-section {
  position: relative;
}

// .bottembutton {
//   position: absolute;
//   left: 72px;
//   bottom: -25.447px;
// }
.btnflx {
  display: flex;
  align-items: center;
}
.topheading {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-right: 6%;
  margin-bottom: 10px;
}
.topheadingSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.heading {
  color: var(--typhograpy-text-2, #323b4b);
  font-family: Causten;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.6px;
}
.Contant {
  color: var(--typhograpy-text-3, #4e5d78);
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: 0.54px;
  width: 198px;
}
.head {
  display: flex;
  margin-top: 10%;
}
.amounthead {
  display: flex;
  width: 139px;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  // align-content: flex-end;
  /* justify-content: space-between; */
  /* align-self: stretch; */
  margin-left: 45px;
  border-radius: 8px;
  border: 1px solid var(--primary-purple-50, #e0ddf3);
}
.fundamt {
  color: var(--typhograpy-text-4, #8a94a6);
  text-align: right;
  font-family: Causten;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px;
}
.cstamt {
  color: var(--typhograpy-text-2, #323b4b);
  text-align: right;
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.3px;
}
.Standardimg {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 22px;
  background: var(--primary-purple-50, #e0ddf3);
}

// DOB
