@import '../../../../common-styles.scss';

#dashboard-highlight-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // justify-content: space-between;
  width: 1160px;
  height: 180px;
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  padding-top: 25px;
  padding-left: 19px;
  padding-bottom: 20px;
  padding-right: 19px;
}
.pending-payment-and-next-scheduled-wrapper {
  border-left: 1px solid rgba(173, 155, 203, 0.25);
  margin-left: 15px;
}
#remaining-balance-title {
  color: $typography-text-3;
  font-size: 12px;
  line-height: 0px;
}
.dashboard-highlight-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // justify-content: space-between;
  width: 1160px;
  height: 180px;
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  padding-top: 25px;
  padding-left: 19px;
  padding-bottom: 20px;
  padding-right: 19px;
}

.dashboard-progress-bar {
  background-color: #653494;
  display: block;
  height: 1px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: -315px;
  width: 200px;
}
// .next-scheduled-payment-wrapper {
//   border-left: 1px solid rgba(173, 155, 203, 0.25);
//   padding: 10px 10px;
//   padding-left: 12px;
//   padding-right: 25px;
//   margin-left: 12px;
//   margin-right: 12px;
//   width: 40%;
//   display: flex;
//   flex-direction: row;
//   align-items: baseline;
//   gap: 25px;
//   justify-content: space-evenly;
// }
.connected-bank-name {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 155%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.045em;
  color: #4e5d78;
  // margin-bottom: -25px;
}

.bank-balance-title {
  margin-left: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #4e5d78;
}
.title {
  // margin-left: 28px !important;
  margin-top: 5px !important;
  margin-bottom: 10px !important ;
}
.title-ledger-detail {
  color: #653494;
  margin-bottom: -10px;
}

.image {
  margin-left: -10px;
  margin-top: 40px;
  // margin-top: 35px !important;
}
.dash {
  font-size: 30px;
  padding-right: 5px;
  padding-left: 5px;
}
.next-payment-details-none {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.015em;
  color: #4e5d78;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 0px;
}
.next-payment-details {
  font-family: 'causten-regular';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;

  display: flex;
  align-items: flex-end;
  letter-spacing: 0.015em;
  color: #4e5d78;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 1px;
}
.ledger-home {
  margin-bottom: -1px;
  align-items: center;
}
#outstanding-balance-number {
  color: #653494;
  font-weight: bold;
  font-size: 48px;
  line-height: 0px;
  font-family: 'causten-extra-bold';
  letter-spacing: 0.015em;
}
#outstanding-balance-number-complete {
  color: #4c2d6a;
  font-weight: bold;
  font-size: 48px;
  line-height: 0px;
  font-family: 'causten-extra-bold';
  letter-spacing: 0.025em;
}

#remaining-credit-number {
  font-family: 'causten-bold';

  color: $accent-success;
  font-weight: bold;
  font-size: 29px;
  line-height: 30px;
  letter-spacing: 0.025em;
  margin-top: 15px;
}

#remaining-credit-number-not-active {
  font-family: 'causten-regular';
  margin-left: 200px !important;
  color: $accent-success;
  font-weight: bold;
  font-size: 29px;
  line-height: 30px;
  letter-spacing: 0.025em;
}

#outstanding-balance-title {
  color: $typography-text-3;
  font-size: 14px;
  line-height: 0px;
}
#remaining-credit-title {
  color: $typography-text-3;
  font-size: 14px;
  line-height: 0px;
  // margin-left: 115px !important;
}
#remaining-credit-title-not-active {
  color: $typography-text-3;
  font-size: 14px;
  line-height: 0px;
}
#original-limit {
  width: 100%;
  display: flex;
  justify-content: end;
  color: #4e5d78;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 4px;
}
#original-limit-not-active {
  margin-top: -9px;
  font-size: 12px;
  color: $typography-text-3;
  line-height: 0px;
  margin-left: 200px !important;
}
.dashboard-balance-available-credit {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0px 10px;
}
.balance-container {
  width: 50%;
  height: 60px;
}
.balance-progress-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.available-credit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 50%;
}
@media only screen and (max-width: 800px) {
}
@media only screen and (max-width: 600px) {
  #dashboard-highlight-container {
    display: flex;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: auto;
    padding: 0;
    width: 100%;
    justify-content: center;
    padding: 20px;
    border-radius: 0px;
  }
  .next-scheduled-payment-wrapper {
    border-left: none;
    width: 100%;
  }
  #dashboard-highlight-container-active {
    box-shadow: none;
  }

  #outstanding-balance-number {
    font-size: 26px;
    line-height: 125%;
  }
  .outstanding-balance-number,
  .remaining-credit-number {
    font-size: 26px !important;
    line-height: 125% !important;
  }
  #remaining-credit-number {
    font-size: 21px;
    line-height: 125%;
  }

  #remaining-credit-title {
    line-height: 20px;
  }

  #outstanding-balance-title {
    line-height: 20px;
  }
  #original-limit {
    justify-content: center;
  }
  .payment-wrapper {
    margin-top: 40px;
    margin-bottom: 15px;
    border-left: 0;
    border-right: 0;
    width: 100%;
  }
}
.outstanding-balance-title {
  color: #4e5d78;
  font-size: 14px;
  line-height: 0px;
}
.outstanding-balance-number {
  color: #653494;
  font-weight: bold;
  font-size: 48px;
  line-height: 0px;
  font-family: 'causten-extra-bold';
  letter-spacing: 0.015em;
}
.remaining-credit-number {
  font-family: 'causten-bold';
  color: #21b17d;
  font-weight: bold;
  font-size: 29px;
  line-height: 30px;
  letter-spacing: 0.025em;
  margin-top: 15px;
}
.outstanding-balance-approve {
  color: var(--typhograpy-text-3, #4e5d78);
  text-align: right;
  /* Body / Medium / Body 4 */
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%; /* 18.6px */
  letter-spacing: 0.54px;
}
.next-scheduled-payment-wrapper {
  width: 360px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
// padding: 10px 10px;
// padding-left: 12px;
// padding-right: 25px;
// margin-left: 12px;
// margin-right: 12px;
// width: 40%;
// display: flex;
// flex-direction: row;
// align-items: baseline;
// grid-gap: 25px;
// gap: 25px;
// justify-content: space-evenly;

.payment-icon-and-text-wrapper-no-payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.payment-text-wrapper-no-payment {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 82px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.next-scheduled-payment-text-style {
  color: var(--typhograpy-text-3, #4e5d78);
  /* Body / Medium / Body 4 */
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 18.6px */
  letter-spacing: 0.54px;
}
.outstanding-balance-date {
  color: var(--typhograpy-text-3, #4e5d78);
  /* Heading / Regular / H6 */
  font-family: Causten;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 25px */
  letter-spacing: 0.3px;
  margin: 0;
}
.payment-img {
  display: flex;
  width: 59px;
  height: 59px;
  padding-left: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 23px;
}
.progress-bar-container-v3 {
  height: 17px;
  align-self: stretch;
}
.width-50-percent {
  // background: var(--secondary-green-700-main, #17B794);
  flex: 1 0 0;
  align-self: stretch;
}
.next-scheduled-payment-wrapper-new {
  border-left: 1px solid rgba(173, 155, 203, 0.25);
  padding: 10px 10px;
  padding-left: 12px;
  padding-right: 25px;
  margin-left: 12px;
  margin-right: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  grid-gap: 25px;
  grid-gap: 25px;
  gap: 25px;
  justify-content: space-evenly;
  align-items: center;
}
#ledger-detail-highlight-container-active-new {
  width: 1160px;
  // height: 318px;
  max-width: 100%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 20px;
  padding-left: 55px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 25px;
  box-shadow: 0px 45px 44px -36px rgba(21, 21, 56, 0.09);
}
.Original-heading {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  justify-content: space-between;
}
#Original-Funding {
  color: var(--primary-purple-800, #4c2d6a);
  font-family: Causten;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.Funding-Amount {
  display: flex;
  width: 139px;
  // height: 56px;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--primary-purple-50, #e0ddf3);
}
#Funding {
  color: var(--typhograpy-text-4, #8a94a6);
  text-align: right;
  font-family: Causten;
  font-size: 13px;
  margin: 0px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 150% */
}
#Amount {
  color: var(--typhograpy-text-2, #323b4b);
  text-align: right;
  /* Heading / Bold / H6 */
  font-family: Causten;
  margin: 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
}
.outer-Advance {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
#Advance-Cost {
  color: var(--typhograpy-text-4, #8a94a6);
  font-family: Causten;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 150% */
  margin: 0px;
}
#Advance-Cost-amount {
  color: var(--primary-purple-700-main, #653494);
  /* Heading / Bold / H5 */
  font-family: Causten;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 30px */
  letter-spacing: 0.6px;
  margin: 0px;
}
#Advance-Cost-factor {
  color: var(--accent-warning-warning-800, #ff9a3d);
  margin: 0px;
}
.outerbox-outer-Advance {
  border-bottom: 1px solid var(--grayscale-black-300, #dcdfe3);
}
.type-outer {
  display: flex;
  justify-content: space-between;
}
#type-stand-head {
  color: var(--typhograpy-text-3, #4e5d78);
  font-family: Causten;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.5px; /* 150% */
  margin: 10px 0px;
}
#standar {
  color: var(--primary-purple-700-main, #653494);
  text-align: right;
  /* Heading / Bold / H8 */
  font-family: Causten;
  font-size: 16px;
  margin: 10px 0px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: 0.3px;
}
#stand-Payment-Date {
  color: var(--typhograpy-text-2, #323b4b);
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  letter-spacing: 0.72px;
}
#chase-n {
  color: var(--typhograpy-text-3, #4e5d78);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 21.7px */
  letter-spacing: 0.21px;
}
#cost-amt-fr {
  margin-top: 0;
  color: var(--grayscale-black-600, #7d8997);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.49px;
  display: flex;
  justify-content: flex-end;
}
#cost-amt-Gr.warning {
  color: var(--accent-warning-warning-800, #ff9a3d);
  font-weight: 600;
}
#cost-amt-Gr.success {
  color: var(--accent-succes-succes-800, #00834f);
  font-weight: 600;
}
#cost-amt-Gr.failed {
  color: var(--accent-succes-succes-800, #ff2b2b);
  font-weight: 600;
}
span.badge {
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 0.54px;
  border-radius: 5px;
  padding: 5px;
  margin-left: 10px;
}
span.badge.error {
  color: var(--accent-danger-danger-700-main, #ff2b2b);
  background: var(--accent-background-danger, #fde8e7);
}
span.badge.success {
  color: #075a0c;
  background: #ebfde7;
}
span.badge.warning {
  color: var(--accent-warning-warning-700-main, #ffb443);
  background: var(--accent-background-warning, #fff6e0);
}
#cost-amt-Gr {
  margin-bottom: 0;
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  line-height: 16px; /* 100% */
  letter-spacing: 0.56px;
  display: flex;
  justify-content: flex-end;
}
#additional-detail-highlight-container-two-new #chase-n {
  margin-top: 0;
}
#additional-detail-highlight-container-two-new #stand-Payment-Date {
  margin-bottom: 0;
}
.main-comp {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 1160px;
  margin-bottom: 30px;
}
#additional-detail-highlight-container-two-new {
  width: 720px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 25px 40px rgba(21, 21, 56, 0.02);
  border-radius: 20px;
  padding: 20px;
  // height: -moz-fit-content;
  min-height: 100%;
}
.Funding-Amount-two {
  display: flex;
  // width: 139px;
  // height: 56px;
  // padding: 8px 12px;
  justify-content: space-between;
  align-items: flex-start;
  // border-radius: 8px;
  // border: 1px solid var(--primary-purple-50, #E0DDF3);
}
.Funding-Amount-two-border {
  border: 1px solid #e0ddf3;
  padding: 4px;
  border-radius: 10px;
}
.img-bg {
  border-radius: 5px;
  opacity: 0.9;
  padding: 5px;
  background: linear-gradient(180deg, #7265ad 0.35%, #7265ad 92.01%);
}
.payment-text-wrapper-no-payment-new {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 82px;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 20px;
}
.payment-icon-and-text-wrapper-no-payment-one {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.bordr {
  border-radius: 8px;
  border: 1px solid var(--primary-purple-50, #e0ddf3);
}
.Funding-Amount-tww {
  display: flex;
  padding: 8px 12px 0px;
  justify-content: space-between;
  align-items: flex-start;
}
#progress-bar-containerr-v2 {
  max-width: 100%;
  height: 4px;
  display: flex;
  margin: 10px;
  align-items: center;
  background-color: #e7e7e7;
  border-radius: 14px;
}
.Original-heading-one {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
#bg-Gclr {
  color: #00834f;
}
#bg-Oclr {
  color: #ff9a3d;
}
.Initial-data {
  display: flex;
}
#status {
  margin-left: 10px;
  color: #ff2b2b;
  background-color: #fde8e7;
  border-radius: 5px;
}
#statusOrg {
  margin-left: 10px;
  color: #ffb443;
  background-color: #fff6e0;
  border-radius: 5px;
}
