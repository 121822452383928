@import '../../../common-styles.scss';

#close-modal-x {
  margin: 15px;
}

#contract-outer-container.no-border {
  border: none;
  margin-bottom: 60px;
}
#contract-outer-container {
  // border: 1px solid grey;
  padding: 10px 20px 0px 20px;
}
.grey-divider-line {
  display: flex;
  width: 460px;
  border-top: 1px solid #dcdfe3;
}
#contract-wrapper {
  padding: 10px;
  border-radius: 20px;
  overflow: hidden;
}
.repeat-advance #contract-wrapper {
  // width: 420px;
  // max-width: 420px;
}
.repeat-advance #contract-text {
  // width: 400px;
  // max-width: 400px;
}
#signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#signature-container p {
  text-align: center;
}

#signature-container h2 {
  text-align: center;
}
.terms-sec h1 {
  display: inline-flex;
  color: var(--typhograpy-text-1, #2f353b);
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 0.49px;
}
.terms-sec h1.heading {
  color: var(--typhograpy-text-1, #2f353b);
  /* Body / Medium / Body 1 */
  font-family: Causten;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 155%;
  letter-spacing: 0.63px;
}
.term-title .header-title {
  text-align: left;
  color: var(--typhograpy-text-2, #323b4b);
  // font-family: Causten;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.54px;
  margin: 10px 0px;
  // text-align: left;
  // margin-bottom: 0;
  // border-bottom: 1px solid #DCDFE3;
  // color: var(--typhograpy-text-2, #323B4B);
  // font-family: Causten;
  // font-size: 32px;
  // font-style: normal;
  // font-weight: 700;
  // line-height: 125%;
  // letter-spacing: 0.48px;
}
.terms-sec p {
  color: var(--typhograpy-text-1, #2f353b);
  /* Body / Regular / Body 3 */
  // font-family: causten;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
  letter-spacing: 0.49px;
}
#contract-text {
  height: 400px;
  max-height: 400px;
  overflow: scroll;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 3.5%;
  line-height: 21.7px;
  font-family: 'causten-regular';
  font-style: normal;
  color: #323b4b;
}
#contract-text p {
  font-weight: 200 !important;
}
#contract-text h2 {
  text-align: center;
  font-size: 13px;
  font-weight: 800;
}
#signature-disclaimer {
  font-size: 11px;
}

#signature-wrapper {
  margin-top: 1px;
  text-align: center;
  height: 100px;
}
#your-signature-label {
  letter-spacing: 0.025em;
  font-size: 12px;
  line-height: 16px;
  color: $typography-text-3;
  margin-bottom: -50px;
  margin-top: 0px;
}
#actual-signature {
  font-family: 'indie-flower';
  font-size: 37px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.no-border #scroll-instructions {
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
}

#scroll-instructions::before {
  height: 1px;
  width: 100px;
  content: '';
  left: 0;
  position: absolute;
  bottom: 20px;
  top: 10px;
  opacity: 0.16;
  background: #4c2d6a;
}
#scroll-instructions::after {
  height: 1px;
  width: 100px;
  content: '';
  top: 10px;
  right: 0;
  position: absolute;
  bottom: 20px;
  opacity: 0.16;
  background: #4c2d6a;
}
#scroll-instructions {
  color: $accent-waring-800;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  font-family: 'airbnb-cereal-medium';
}

#force-load-of-font {
  color: white;
  font-family: 'indie-flower';
}

#contract-text::-webkit-scrollbar {
  width: 5px;
}

#contract-text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#canvas-wrapper {
  max-width: 100%;
  // overflow: scroll;
  width: 100%;
}

#canvas-wrapper::-webkit-scrollbar {
  width: 0px;
}

#canvas-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}

#canvas-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#signed-text {
  color: $accent-success-800;
}

#contract-text h1 {
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #323b4b;
  font-weight: 600;
  line-height: 19.5px;
  margin-bottom: 10px;
}
#contract-text h4 {
  display: flex;
  justify-content: center;
  font-weight: 200;
  margin: 0%;
}
#contract-text h3 {
  font-size: 12.5px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #323b4b;
  font-weight: 700;
  line-height: 19.5px;
  margin-bottom: 10px;
}
#contract-text h5 {
  font-size: 12.5px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: #323b4b;
  font-weight: 700;
  line-height: 19.5px;
  margin-bottom: 10px;
}
#contract-text h6 {
  display: flex;
  justify-content: end;
  margin-right: 15px;
}
.no-border #contract-text p {
  color: var(--typhograpy-text-2, #323b4b);
  font-family: 'causten-regular';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}
#contract-text p {
  font-size: 12.5px;
  margin-top: 2px;
  margin-bottom: 5px;
  font-weight: 300;
  line-height: 19.5px;
  margin-bottom: 16px;
  //margin-left: 8px;
}
#scroll-credit-non-ny {
  color: var(--primary-purple-700-main, #653494);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */

  margin: 0%;
}
#scroll-credit {
  color: var(--primary-purple-700-main, #653494);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Causten;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.35px;
  margin: 0%;
}
.agreementCreditNonNy {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 7px;
  background: var(--primary-purple-background, #f4f2fa);
}
.agreementCredit {
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 7px;
  background: var(--primary-purple-background, #f4f2fa);
}

.checkboxdata {
  color: var(--grayscale-black-800, #2f353b);
  font-family: Causten;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}
.checkboxdata-message-non-ny {
  flex-direction: row;
  color: #653494;
  font-size: 12px;
  margin-top: -20px;
  /* margin-bottom: 20px; */
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  margin-left: 2.5px;
}
.checkboxdata-message-bank {
  flex-direction: row;
  color: #653494;
  font-size: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.checkboxdata-message {
  flex-direction: row;
  color: #653494;
  font-size: 12px;
  margin-top: -20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.refunding-popup div#signature-wrapper {
  margin-bottom: 40px;
}
div#canvas-wrapper {
  display: block;
  float: right;
  width: 100%;
}
