.l-radio {
  padding: 6px;
  border-radius: 50px;
  display: inline-flex;
  cursor: pointer;
  transition: background 0.2s ease;
  margin: 8px 0;
  -webkit-tap-highlight-color: transparent;
}
.radio-sub-label {
  color: #8a94a6 !important;
  font-weight: 500 !important;
  padding-right: 50px !important;
}
.l-radio input {
  vertical-align: middle;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  background: none;
  border: 0;
  box-shadow: inset 0 0 0 1px #7165ad;
  box-shadow: inset 0 0 0 1.5px #7165ad;
  appearance: none;
  padding: 0;
  margin: 0;
  transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
  pointer-events: none;
}
.l-radio input:focus {
  outline: none;
}
.l-radio input:checked {
  box-shadow: inset 0 0 0 3px white, 0 0 0 1.5px #7165ad;
  background-color: #7165ad;
}
.l-radio span {
  vertical-align: middle;
  display: inline-block;
  line-height: 20px;
  padding: 0 8px;
  font-weight: bold;
  font-size: 14px;
  color: #323b4b;
}

.radio-group-label {
  font-weight: bold;
  font-size: 12px;
  color: #323b4b;
  font-family: 'airbnb-cereal-medium';
  margin-bottom: 3px;
}
