.horizontal-divider {
  background-color: #dcdfe3;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.statement-explanation {
  color: #8a94a6;
  font-size: 12px;
}

.manual-upload-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #323b4b;
}
.manual-upload-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.045em;
  text-align: left;
  color: #8a94a6;
}
.uploaded-file-name {
  font-family: "causten-regular";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #2f353b;
}
.manual-text-black {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.035em;
  text-align: left;
  color: #090b0c;
}
.manual-upload-main-wrapper {
  width: Fill (480px);
  height: Hug (497px);
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #f4f4f4;
  gap: 28px;
}
#icon-upload-wrapper {
  cursor: pointer;
}

.manual-text-purple {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: #653494;
  cursor: pointer;
}
.header-and-cancel-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cancel-text-style {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.015em;
  text-align: right;
  cursor: pointer;
  color: #4e5d78;
}
.bankstatement {
  display: flex;
  align-items: center;
}
#statementlink {
  color: var(--primary-purple-700-main, #653494);
  font-family: Causten;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.3px;
  margin-left: 10px;
}
